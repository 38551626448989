
<template>
  <!-- 公告通知 -->
  <div class="helpguide">
    <Newslist :list="dataList" @childByValue="childByValue"></Newslist>
  </div>
</template>
    
<script>
import Newslist from "@/components/newslist.vue";
import apiUrls from '@api'
export default {
  components: {
    Newslist,
  },
  name: "Notice",
  data() {
    return {
        dataList:[]
    };
  },
  created(){
    this.getList()
  },
  methods: {
    childByValue(childByValue, nodename) {
        console.log(childByValue, nodename)
    //   this.$emit("childByValue", childByValue, nodename);
    },
    getList () {
      apiUrls.getinfoopen({node: 'rdjz_gzdt'}).then(res=>{
        this.dataList= res.results.data
      })
    }
  },
};
</script>
    
<style lang="less" scoped>
.helpguide{
    background: #fff;
    padding: 15px;
    width: 1200px;
    margin: 10px auto;
}
</style>
    